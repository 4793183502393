<template>
  <div class="eidt-question">
    <div class="tab">
      <div class="back" @click="goBack()">
        <i class="iconfont icon-fanhui"></i> <span>返回</span>
      </div>
      <div class="center">
        <el-input v-model="paperName" placeholder="请输入试卷标题" style="width: 300px;"></el-input>
      </div>
      <div class="reImport">
        <el-button type="primary" @click="savePaper()">保存试卷</el-button>
      </div>
    </div>
    <div class="content">
      <p class="title">第I卷(单选题)</p>
      <div class="item" v-for="(item, index) in singalProblem" :key="item.questionId">
        <p class="margin2">{{ index + 1 }}.{{ item.question.context }}</p>
        <p v-for="(el, index1) in item.question.option" :key="index1" class="margin2">
          {{ el.optionChoise }}<span style="margin-right: 5px;">.</span>{{ el.optionMessage }}

        </p>
        <p class="opt">
          <span>
            <!-- <el-input v-model.number="item.score" placeholder="" style="width: 80px;"
              size="mini"></el-input> -->
              <el-input-number v-model.number="item.score" controls-position="right"
                                :min="0" :step="0.1" :step-strictly="true" size="mini" style="width: 100px;">
            </el-input-number>
              分</span>
          <span class="multiple-opt">
            <el-button type="text" @click="moveUp(index, singalProblem)">上移</el-button>
            <el-button type="text" @click="moveDown(index, singalProblem)">下移</el-button>
            <el-button type="text" @click="removeProblem(index, singalProblem)">删除</el-button>
          </span>
        </p>
      </div>
    </div>
    <div class="content">
      <p class="title">第II卷(多选题)</p>
      <div class="item" v-for="(item, index) in multipleProblem" :key="item.questionId">
        <p class="margin2">{{ singalProblem.length + index + 1 }}.{{ item.question.context }}</p>
        <p v-for="(el, index1) in item.question.option" :key="index1" class="margin2">
          {{ el.optionChoise }}<span style="margin-right: 5px;">.</span>{{ el.optionMessage }}
        </p>
        <p class="opt">
          <span>
            <!-- <el-input v-model.number="item.score" placeholder="" style="width: 80px;"
              size="mini"></el-input> -->
              <el-input-number v-model.number="item.score" controls-position="right"
                                :min="0" :step="0.1" :step-strictly="true" size="mini" style="width: 100px;">
            </el-input-number>
              分</span>
          <span class="multiple-opt">
            <el-button type="text" @click="moveUp(index, multipleProblem)">上移</el-button>
            <el-button type="text" @click="moveDown(index, multipleProblem)">下移</el-button>
            <el-button type="text" @click="removeProblem(index, multipleProblem)">删除</el-button>
          </span>
        </p>
      </div>
    </div>
    <div class="content">
      <p class="title">第III卷(判断题)</p>
      <div class="item" v-for="(item, index) in judgeProblem" :key="item.questionId">
        <p class="margin2">{{ singalProblem.length + multipleProblem.length + index + 1 }}.{{ item.question.context }}</p>
        <p class="margin2">A<span style="margin-right: 5px;" class="margin2">.</span>正确</p>
        <p class="margin2">B<span style="margin-right: 5px;" class="margin2">.</span>错误</p>
        <p class="opt">
          <span>
            <!-- <el-input v-model.number="item.score" placeholder="" style="width: 80px;"
              size="mini">
            </el-input> -->
            <el-input-number v-model.number="item.score" controls-position="right"
                                :min="0" :step="0.1" :step-strictly="true" size="mini" style="width: 100px;">
            </el-input-number>
            分
          </span>
          <span class="multiple-opt">
            <el-button type="text" @click="moveUp(index, judgeProblem)">上移</el-button>
            <el-button type="text" @click="moveDown(index, judgeProblem)">下移</el-button>
            <el-button type="text" @click="removeProblem(index, judgeProblem)">删除</el-button>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>




export default {
  name: 'EidtQuestion',
  data() {
    return {
      paperId: "",
      paperName: '',
      singalProblem: [],
      multipleProblem: [],
      judgeProblem: [],
    };
  },
  components: {},
  created() {
    let paperId = this.$route.params.id;
    this.paperId = paperId;
    this.getExam()


  },
  methods: {
    async getExam() {
      this.singalProblem = []
      this.multipleProblem = []
      this.judgeProblem = []

      const result = await this.Api.databaseManage.getPaperDetial({ paperId: this.paperId });

      // if (result.data.code != 200) {
      //   return
      // }
      this.paperName = result.data.paper.name;
      result.data.paperQuestions.forEach(element => {
        element.question['option'] = JSON.parse(element.question.options);
        switch (element.type) {
          case 1:
            this.singalProblem.push(element);
            break;
          case 2:
            this.multipleProblem.push(element);
            break;
          case 3:
            this.judgeProblem.push(element);
            break;
          default:
            break;
        }


      });
      console.log("🚀 ~ getExam ~ this.judgeProblem:", this.judgeProblem)
      console.log("🚀 ~ getExam ~ this.multipleProblem:", this.multipleProblem)
      console.log("🚀 ~ getExam ~ this.singalProblem:", this.singalProblem)


    },
    goBack() {

      if (this.$store.state.historyRoute && this.$store.state.historyRoute.includes('autoVolumes')) {
        this.$router.push('/admin/database-manage/autoVolumes');
      } else {
        this.$router.push('/admin/database-manage/volumesRecords');
      }
    },
    moveUp(index, objectsArray) {
      if (index > 0 && index < objectsArray.length) {
        let temp = objectsArray[index];
        this.$set(objectsArray, index, objectsArray[index - 1]);
        this.$set(objectsArray, index - 1, temp);
      }

    },
    moveDown(index, objectsArray) {
      console.log("🚀 ~ moveDown ~ index, objectsArray:", index, objectsArray)
      if (index >= 0 && index < objectsArray.length - 1) {
        let temp = objectsArray[index];
        this.$set(objectsArray, index, objectsArray[index + 1]);
        this.$set(objectsArray, index + 1, temp);
      }
    },
    removeProblem(index, objectsArray) {
      objectsArray.splice(index, 1);
    },
    async savePaper() {
      let score = 0
      this.singalProblem.forEach((element, index) => {
        element.order = index + 1;
        score += element.score
      });
      this.multipleProblem.forEach((element, index) => {
        element.order = this.singalProblem.length + index + 1;
        score += element.score

      });
      this.judgeProblem.forEach((element, index) => {
        element.order = this.singalProblem.length + this.multipleProblem.length + index + 1;
        score += element.score

      });
      let arr = this.singalProblem.concat(this.multipleProblem, this.judgeProblem);
      let data = {
        paperId: this.paperId,
        name: this.paperName,
        questionNum: this.singalProblem.length + this.multipleProblem.length + this.judgeProblem.length,
        fullScore: score,
        paperQuestions: arr
      }
      const result = await this.Api.databaseManage.savePaper(data);
      if (result.code != 200) {
        this.$message.error(result.message);
        return;
      }
      this.$message.success('保存试卷成功!');
      this.$router.push('/admin/database-manage/volumesRecords');


    }

  },
};
</script>

<style scoped lang="scss">
.eidt-question {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  margin-top: 20px;
  overflow-y: hidden;
  border: 1px solid #d8d8d8;
  height: calc(100% - 20px);

  .tab {
    height: 48px;
    margin: 20px 0;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    position: relative;
    // margin-left: 100px;
    justify-content: space-between;

    div {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      // margin-right: 30px;
      padding: 0 10px;
      cursor: pointer;
      bottom: -1px;
      box-sizing: border-box;

      i {
        margin-right: 3px;
      }
    }

    .back {
      // position: absolute;
      // left: -80px;
      display: flex;
      align-items: center;
      color: #3bafda;
    }
  }

  .content {
    margin: 0 20px;
    overflow: hidden;


    .title {
      padding: 10px 0;
      color: #3bafda;
      border: 1px solid #d8d8d8;
      font-weight: 800;
      width: 40%;
      box-sizing: border-box;
      text-align: center;
    }

    .item {
      position: relative;
      overflow: hidden;
      // padding: 0 10px;
      margin-bottom: 20px;
      border: 1px solid #f5f7fa;
      border-radius: 10px;

      &:hover {
        border-color: #3bafda;
      }

      .margin2 {
        margin: 10px 10px;
      }

      .opt {
        // position: absolute;
        // top: 50%;
        // transform: translateY(-50%);
        // left: 50%;
        text-align: right;
        border-top: 1px solid #f5f7fa;
        margin: 0;
        padding: 5px 0;
        padding-right: 20px;

        .multiple-opt {
          margin-left: 60px;

        }

        button {
          color: #3bafda;
        }
      }
    }
  }


}

::v-deep .el-form-item {
  margin-bottom: 20px;
}

::v-deep .el-input__inner {
  text-align: center;
}

.red {
  color: red;
}

.btn-padding {
  padding: 12px 20px;
}
</style>
